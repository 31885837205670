import { graphql } from 'gatsby';
import * as he from 'he';
import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PageHeader from '../components/paragraphs/page-header/page-header';
import BlogItem from '../components/blocks/blog-item/blog-item';
import Container from '../components/container/container';

const Category = ({ data, location, pageContext }) => {
  const { langCode, nid } = pageContext;
  return (
    <Layout location={location} lang={langCode} nid={nid}>
      <SEO
        title={he.decode(data.taxonomyTermBlogCategories.name)}
        lang={langCode}
        nid={nid}
      />
      <PageHeader
        colors={'groen'}
        align={'gecentreerd'}
        title={data.taxonomyTermBlogCategories.name}
        breadcrumbs={[
          { url: '/', title: 'Home' },
          { url: '/inspiratie', title: 'Inspiratie' }
        ]}
        langCode={langCode}
      ></PageHeader>
      <Container style={{ maxWidth: 960 }}>
        {data.allNodeArticle.nodes &&
          data.allNodeArticle.nodes.length > 0 &&
          data.allNodeArticle.nodes
            .filter(
              item =>
                item.relationships.field_category.id ===
                data.taxonomyTermBlogCategories.id
            )
            .reverse()
            .map(item => (
              <BlogItem
                title={item.title}
                date={item.created}
                link={item.path.alias}
                image={
                  item.relationships.field_media.field_media_image
                    .imageDerivatives.links.blog_overview.href
                }
                langCode={langCode}
              >
                {item.body && item.body.summary && <p>{item.body.summary}</p>}
              </BlogItem>
            ))}
      </Container>
    </Layout>
  );
};
export default Category;

export const query = graphql`
  query($slug: String!) {
    taxonomyTermBlogCategories(
      path: { alias: { eq: $slug } }
      status: { eq: true }
    ) {
      id
      name
      path {
        alias
      }
      relationships {
        field_media {
          field_media_image {
            imageDerivatives {
              links {
                story_paragraph {
                  href
                }
              }
            }
            alt
          }
        }
      }
    }

    allNodeArticle {
      nodes {
        title
        created
        body {
          summary
          processed
        }
        path {
          alias
        }
        relationships {
          field_category {
            id
          }
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                  blog_overview_webp {
                    href
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
